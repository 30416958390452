import React from 'react'

const ChevronUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="35"
      viewBox="0 0 38 35"
      fill="none"
    >
      <path
        d="M19.8193 13.8731C19.4414 13.4902 18.8277 13.4902 18.4498 13.8731L12.6453 19.7548C12.2674 20.1377 12.2674 20.7596 12.6453 21.1425C13.0232 21.5254 13.6369 21.5254 14.0148 21.1425L19.136 15.9532L24.2572 21.1394C24.6351 21.5224 25.2488 21.5224 25.6267 21.1394C26.0046 20.7565 26.0046 20.1347 25.6267 19.7517L19.8223 13.8701L19.8193 13.8731Z"
        fill="#005E75"
      />
    </svg>
  )
}

export default ChevronUp
