import {StoryblokComponent, storyblokEditable} from '@storyblok/react'
import ChevronDown from 'components/icons/ChevronDown'
import ChevronUp from 'components/icons/ChevronUp'
import React, {useState} from 'react'
import {
  CollapsibleTableAddressItemStoryblok,
  CollapsibleTableLinkItemStoryblok,
  CollapsibleTableStoryblok,
} from 'types/storyblok-types'

type CollapsibleTableContainerPropsType = {
  blok: CollapsibleTableStoryblok
}

type CollapsibleTableBlokPropsType = {
  open_by_default: CollapsibleTableStoryblok['open_by_default']
  title: string
  items: (
    | CollapsibleTableAddressItemStoryblok
    | CollapsibleTableLinkItemStoryblok
  )[]
  blok: CollapsibleTableStoryblok
}

export function CollapsibleTableBlok({
  open_by_default,
  title,
  items,
  blok,
}: CollapsibleTableBlokPropsType) {
  const [expanded, setExpanded] = useState<boolean>(open_by_default ?? true)

  const toggle = () => {
    setExpanded(!expanded)
  }

  return (
    <div className="flex flex-col items-start justify-start gap-4 pb-8">
      <button
        className="flex cursor-pointer items-center justify-start gap-1"
        onClick={toggle}
      >
        <p className="font-semibold text-dats-s3">{title}</p>
        {expanded ? <ChevronUp /> : <ChevronDown />}
      </button>

      <div
        className={`grid w-full grid-flow-row grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2 lg:grid-cols-4 ${
          expanded ? 'block' : 'hidden'
        }`}
        {...storyblokEditable(blok)}
      >
        {items.map(item => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    </div>
  )
}

const CollapsibleTableContainer = ({
  blok,
}: CollapsibleTableContainerPropsType) => {
  return (
    <CollapsibleTableBlok
      open_by_default={blok.open_by_default ?? false}
      title={blok.title ?? ''}
      items={blok.content ?? []}
      blok={blok}
    />
  )
}

export default CollapsibleTableContainer
